import React from "react"
import "./ChatGptIndustries.css"
const ChatGptIndustries = () => {
  const industries = [
    {
      title: "Healthcare",
      description:
        "Provide instant answers to patient queries, manage appointment scheduling, and assist with documentation.",
      icon: (
        <i
          className="icon-ai-ml-development-services-healthcare"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Finance",
      description:
        "Enhance customer support, automate document processing, and streamline compliance tasks.",
      icon: (
        <i
          className="icon-ai-ml-development-services-finance"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Retail",
      description:
        "Automate customer interactions, assist with product recommendations, and improve inventory management.",
      icon: (
        <i
          className="icon-ai-ml-development-services-retail"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Education",
      description:
        "Create personalized learning experiences, automate grading, and assist with administrative tasks.",
      icon: (
        <i
          className="icon-machine-learning-development-education"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Real Estate",
      description:
        "Automate property inquiries, assist with lead generation, and provide virtual tours to enhance customer experiences.",
      icon: (
        <i
        className="icon-ai-chatbot-developmet-realestate"
        style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "E-commerce",
      description:
        "Automate customer service, generate product descriptions, and assist with order processing.",
      icon: (
        <i
          className="icon-ai-ml-development-services-e-commerce"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
  ]

  return (
    <div
      className="min-h-screen flex items-center justify-center text-white bg-cover bg-center relative"
      style={{
        backgroundImage: `url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2025/01/businessman-search-new-opportunities-international-business-typing-laptop-background-world-map-hologram-scaled.jpg")`,
      }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="relative z-10 p-6 md:p-16 rounded-lg w-full">
        {/* Title */}
        <h1 className="text-3xl md:text-5xl font-bold my-4 md:mb-6 text-center text-white">
          Industries That Benefit from ChatGPT Integration
        </h1>

        {/* Subtitle */}
        <p className="text-lg md:text-xl text-gray-300 text-center  mx-auto mb-8">
          Our ChatGPT integration services are ideal for a wide range of
          industries. Here are some sectors that can benefit from AI-powered
          communication and automation
        </p>

        {/* Industry Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full mx-auto gap-4">
          {industries.map((industry, index) => (
            <div className="feature-tag">
              <div className="flex flex-col items-start h-full">
                <div
                  className="w-20 h-20 md:w-24 md:h-24 lg:w-26 lg:h-26 rounded-2xl text-white flex items-center justify-center mb-6 text-2xl md:text-4xl lg:text-6xl"
                  style={{
                    background: "rgba(255, 255, 255, 0.10)",
                  }}
                >
                  {industry.icon}
                </div>
                <h3 className="text-xl lg:text-2xl font-bold text-white mb-4">
                  {industry.title}
                </h3>
                <div className="text-left font-normal text-lg text-white overflow-hidden">
                  {industry.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ChatGptIndustries
